function sortOllamaModels({input}) {
    const order = ['q4_0', 'q6_K', 'q8_0', 'fp16']

    function parseAndGroupData(data) {
        const lines = data.split('\n')
        const grouped = {}

        for (const line of lines) {
            const trimmedLine = line.trim()
            if (trimmedLine) {
                const [fullName] = trimmedLine.split(/\s+/)
                const [name, version] = fullName.split(':')
                if (!grouped[name]) grouped[name] = new Set()
                grouped[name].add(version || 'latest')
            }
        }

        return grouped
    }

    function sortVersions(versions) {
        return Array.from(versions).sort((a, b) => {
            const aIndex = order.findIndex(v => a.includes(v))
            const bIndex = order.findIndex(v => b.includes(v))
            if (aIndex === -1 && bIndex === -1) return a.localeCompare(b)
            return (aIndex === -1 ? Infinity : aIndex) - (bIndex === -1 ? Infinity : bIndex)
        })
    }

    const groupedData = parseAndGroupData(input)
    const commands = Object.keys(groupedData).sort().flatMap(name =>
        sortVersions(groupedData[name]).map(version => `ollama pull ${name}:${version}`)
    )

    const reversedCommands = commands.reverse()
    const result = reversedCommands.join('\n') + '\n\n'

    return { result }
}

export { sortOllamaModels }