import { getWeatherByCity } from "../../services/weatherService"
import { useState } from "react"
import './WeatherPage.css'

const WeatherPage = () => {
  const [weatherData, setWeatherData] = useState(null)

  const handleSearch = async (event) => {
    event.preventDefault();
    const city = event.target.elements.city.value
    try {
      const data = await getWeatherByCity(city)
      setWeatherData(data)
    } catch (error) {
      console.error("Error fetching weather data:", error)
    }
  };

  return (
      <div className="container">
        <h1 style={{color: "var(--primary-color)"}}>Weather Tool</h1>
        <form onSubmit={handleSearch}>
          <input type="text" name="city" placeholder="Enter city name" />
          <button type="submit">Get Weather</button>
        </form>
        {weatherData && (
            <div className="weather-data">
              <h2 style={{color: "var(--primary-color)"}}>{weatherData.name}, {weatherData.sys.country}</h2>
              <p style={{color: "var(--text-color)"}}>Temperature: {weatherData.main.temp}°C</p>
              <p style={{color: "var(--text-color)"}}>Weather: {weatherData.weather[0].description}</p>
            </div>
        )}
      </div>
  )
}

export default WeatherPage;
