import {
  faHome,
  faUser,
  faEnvelope,
  faPhone,
  faGlobe,
  faCopy,
  faArrowsRotate,
  faSearch,
  faCog,
  faBell,
  faCalendar,
  faFile,
  faFolder,
  faImage,
  faVideo,
  faMusic,
  faLock,
  faUnlock,
  faShoppingCart,
  faHeart,
  faStar,
  faComment,
  faShare,
  faTrash,
  faEdit,
  faSave,
  faUpload,
  faDownload
} from '@fortawesome/free-solid-svg-icons'

const IconsList = {
  home: faHome,              // Icône de maison, généralement utilisée pour la page d'accueil
  user: faUser,              // Icône d'utilisateur, pour les profils ou la connexion
  envelope: faEnvelope,      // Icône d'enveloppe, pour les e-mails ou les messages
  phone: faPhone,            // Icône de téléphone, pour les contacts ou les appels
  globe: faGlobe,            // Icône de globe, pour les sites web ou les langues
  copy: faCopy,              // Icône de copie, pour copier du texte ou des éléments
  transform: faArrowsRotate, // Icône de transformation, pour les actions de rotation ou de conversion
  search: faSearch,          // Icône de loupe, pour la recherche
  settings: faCog,           // Icône d'engrenage, pour les paramètres ou la configuration
  notification: faBell,      // Icône de cloche, pour les notifications
  calendar: faCalendar,      // Icône de calendrier, pour les dates ou les événements
  file: faFile,              // Icône de fichier, pour les documents
  folder: faFolder,          // Icône de dossier, pour l'organisation des fichiers
  image: faImage,            // Icône d'image, pour les photos ou les galeries
  video: faVideo,            // Icône de vidéo, pour les contenus vidéo
  music: faMusic,            // Icône de note de musique, pour l'audio ou la musique
  lock: faLock,              // Icône de cadenas fermé, pour la sécurité ou le verrouillage
  unlock: faUnlock,          // Icône de cadenas ouvert, pour le déverrouillage
  cart: faShoppingCart,      // Icône de panier, pour le e-commerce
  like: faHeart,             // Icône de cœur, pour les likes ou les favoris
  star: faStar,              // Icône d'étoile, pour les notes ou les favoris
  comment: faComment,        // Icône de bulle de dialogue, pour les commentaires
  share: faShare,            // Icône de partage, pour partager du contenu
  delete: faTrash,           // Icône de poubelle, pour la suppression
  edit: faEdit,              // Icône de crayon, pour l'édition
  save: faSave,              // Icône de disquette, pour la sauvegarde
  upload: faUpload,          // Icône de flèche vers le haut, pour le téléversement
  download: faDownload       // Icône de flèche vers le bas, pour le téléchargement
}

export default IconsList