import React, { useState } from 'react'
import './OllamaSortPage.css'
import { sortOllamaModels } from "../../services/OllamaSort"
import Button from "../../components/Button/Button"

const OllamaSortPage = () => {
    const [inputData, setInputData] = useState('')
    const [outputData, setOutputData] = useState('')

    const handleTransform = async () => {
        try {
            const result = sortOllamaModels({ input: inputData })
            setOutputData(result.result)
        } catch (error) {
            console.error('Error transforming data: ', error)
        }
    }

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(outputData).then(() => {
            alert("Copied to clipboard!")
        }, (err) => {
            console.error('Could not copy text: ', err)
        })
    }

    return (
        <div className="flex flex-col md:flex-row justify-between items-center h-screen p-4 relative">
            <textarea
                value={inputData}
                onChange={(e) => setInputData(e.target.value)}
                placeholder="Enter data here..."
                className="w-full md:w-1/2 h-3/4 p-4 border rounded shadow mb-4 md:mb-0"
            />
            <div className="absolute top-4 right-4 flex space-x-2">
                <Button onClick={handleTransform} iconName='transform'>Transform</Button>
                <Button onClick={handleCopyToClipboard} iconName='copy'>Copy</Button>
            </div>
            <textarea
                value={outputData}
                readOnly
                placeholder="Output will be here..."
                className="w-full md:w-1/2 h-3/4 p-4 border rounded shadow mb-4 md:mb-0"
            />
        </div>
    )
}

export default OllamaSortPage
