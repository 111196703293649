import { fetchWeatherData } from '../API/openWeatherMapApi';

export const getWeatherByCity = async (city) => {
  try {
    return await fetchWeatherData(city);
  } catch (error) {
    // Handle error or rethrow
    throw error;
  }
};
