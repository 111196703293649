import React from 'react'
// import Sidebar from '../../components/Sidebar/Sidebar'
import MainContent from '../../components/MainContent/MainContent'
import './HomePage.css'

const HomePage = () => {
    return (
        <div className="dashboard">
            {/*<Sidebar />*/}
            <MainContent />
        </div>
    )
}

export default HomePage
