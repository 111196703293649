import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css'
import HomePage from "./pages/Home/HomePage"
import OllamaSortPage from "./pages/OllamaSort/OllamaSortPage"
import WeatherPage from "./pages/Weather/WeatherPage"
import HomeButton from "./components/HomeButton/HomeButton";

function App() {
    return (
        <Router>
            <div className="App">
                <HomeButton />
                <Routes>
                    <Route exact path="/" element={<HomePage />} />
                    <Route path="/ollama-sort" element={<OllamaSortPage />} />
                    <Route path="/weather" element={<WeatherPage />} />
                </Routes>
            </div>
        </Router>
    )
}

export default App
