import React from 'react'
import Card from '../Card/Card'
import './MainContent.css'
import {useNavigate} from "react-router-dom";

const MainContent = () => {
    const navigate = useNavigate()

    const handleNavigateToOllamaSort = () => {
        navigate('/ollama-sort')
    }

    const handleNavigateToWeatherPage = () => {
        navigate('/weather')
    }

    const OllamaToolDescription = "Permet de trier les modèles Ollama et de les transformer en commandes pull."
    const WeatherToolDescription = "Affiche la météo pour une ville donnée."

    return (
        <div className="main-content">
            <header>
                <h1>ARCH Tools</h1>
            </header>
            <section className="cards-container">
                    <Card title="Ollama Sort" description={OllamaToolDescription} onClick={handleNavigateToOllamaSort} />
                    <Card title="Weather Tool" description={WeatherToolDescription} onClick={handleNavigateToWeatherPage} />
                    {/* ... rest of cards ... */}
            </section>
        </div>
    )
}

export default MainContent
