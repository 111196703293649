import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Button.css'
import IconsList from './IconsList'

const Button = ({ onClick, children, className, iconName }) => {
  return (
    <button onClick={onClick} className={`custom-btn ${className}`}>
      {iconName && <FontAwesomeIcon icon={IconsList[iconName]} />}
      {children}
    </button>
  )
}

export default Button
