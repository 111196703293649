import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../Button/Button'
import './HomeButton.css'

const HomeButton = () => {
    const navigate = useNavigate()

    const handleNavigateHome = () => {
        navigate('/')
    }

    return (
        <Button onClick={handleNavigateHome} className="home-button" iconName="home">
            {/*Home*/}
        </Button>
    )
}

export default HomeButton